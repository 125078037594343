.App {
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 0 0 5px 0;
  padding: 0;
  overflow: hidden;
  background-color: #daa520;
}

li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #fafad2;
}

li p{
  color: white;
}


.column{
  float: left;
  height: 100%;
}
.img-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 0 10px 0 10px;
}
.img-container img{
  max-width: 100%;
  max-height: 100%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  width: 25%;
}

table tr td{
  padding: 2px 10px;
  text-align: left;
}